import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {JWTService} from '../jwt.service';
import {UserAuthService} from '../user-auth.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    constructor(
        private jwt: JWTService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const reqWithAuth = this.addAuthenticationToken(req);
        return next.handle(reqWithAuth).pipe(catchError(err => {
            const oldRequestAuthHeader = reqWithAuth.headers.get('Authorization');
            // If the auth token has changed since the old request, we should try it again with the new credentials
            if (oldRequestAuthHeader && !oldRequestAuthHeader.includes(this.jwt.authToken)) {
                return next.handle(this.addAuthenticationToken(req));
            }
            // If the response is an error to the login or refresh we should just throw an error
            // and log the user out because something fatal happened on the login / refresh route
            if (['refresh', 'login'].some(x => req.url.includes(x))) {
                if (req.url.includes('refresh')) {
                    this.jwt.logOutUser();
                }

                return throwError(err);
            }

            if (err.status !== 401) {
                return throwError(err);
            }

            return JWTInterceptor.handleAuthError(this.jwt, err, () => next.handle(this.addAuthenticationToken(req)));
        }));
    }

    static handleAuthError<T extends Observable<any>>(jwt: JWTService, err: any, obsFn: () => T = () => <T>of(null)) {
        if (jwt.tokenRefreshInProgress || jwt.loginModalSubscription && !jwt.loginModalSubscription.closed) {
            return jwt.tokenSubject$.pipe(
                filter(token => !!token),
                take(1),
                switchMap(obsFn)
            );
        }
        if (typeof jwt.refreshToken !== 'string' || !jwt.refreshToken.length) {
            jwt.logOutUser();

            return throwError(err);
        }

        return jwt.refreshTokens()
            .pipe(
                switchMap((tokens: {token: string; refresh_token: string}) => {
                    jwt.setToken(tokens.token, tokens.refresh_token, false);
                    jwt.tokenRefreshInProgress = false;

                    jwt.updateTokenSubject();

                    return obsFn().pipe(
                        catchError(err => throwError(err))
                    );
                }),
                catchError(err => {
                    // The login modal opened, we should wait to the user to enter their credentials
                    // and if the tokenSubject emits, we can continue with the new tokens
                    if (jwt.loginModalSubscription && UserAuthService.hasUser) {
                        return jwt.tokenSubject$.pipe(filter(token => !!token),
                            take(1),
                            switchMap(obsFn)
                        );
                        // We got an error response to some of the authentication related routes, or got a 401
                        // response after refreshing the tokens. Something went wrong with the refresh, we should log the user out
                    } else if (['refresh', 'auth'].some(fragment => err.url.includes(fragment)) || err.status === 401) {
                        jwt.logOutUser();
                        return throwError(err);
                        // We got an error for the request, but it's probably not an authentication error, but
                        // a failed back-end request. We should continue as usual, the refresh should have happened correctly
                    }
                    return obsFn();
                })
            );
    }

    addAuthenticationToken(req: HttpRequest<any>, token: string = this.jwt.authToken): HttpRequest<any> {
        if (typeof token !== 'string' || !token.length) return req;

        return req.clone({
            setHeaders: {
                Authorization: `JWT ${token}`,
            },
        });
    }
}
